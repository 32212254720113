@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input,
textarea {
  background-color: rgb(244, 254, 242);
  border: 1px solid #9ac08e63;
  padding: 10px;
}

input {
  -webkit-appearance: none !important;
  appearance: none !important;
}

:root {
  --primary-color: rgb(173, 182, 98);
  --success-color: rgb(42, 165, 58);
  --error-color: rgb(189, 7, 7);
  --secondary-color: white;
  --orange: rgb(252, 197, 31);
  --bp-medium: 768px;
  --bp-small: 576px;
  /* --animate-duration: 1000ms;  */
}

/* utils */
.border-top {
  border-top: 4px solid var(--primary-color);
}

.border-top-1 {
  border-top: 1px solid var(--primary-color);
}

.border-top-2 {
  border-top: 2px solid var(--primary-color);
}

.border-top-3 {
  border-top: 3px solid var(--primary-color);
}

.border-warning {
  border: 2px solid rgb(140, 10, 10) !important;
}

.text-primary {
  color: var(--primary-color);
}

.position {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-success {
  color: var(--success-color);
}

.text-green {
  color: var(--success-color);
  text-align: center;
  text-decoration: underline;
}

.text-orange {
  color: var(--orange);
}

.text-danger {
  color: var(--error-color);
}

.text-black {
  color: black;
}

.text-light {
  color: rgba(109, 109, 109, 0.8);
}

.text-white {
  color: #fff;
}

.bg-orange {
  background-color: var(--orange);
}

.bg-success {
  background-color: var(--success-color);
}

.container {
  padding: 0.5rem 2rem 2rem 2rem;
}

.rounded {
  border-radius: 4px;
}

/* Text utils */
.decoration-none {
  text-decoration: none;
}

/* Shadows */
.shadow-1 {
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
}

.shadow-2 {
  box-shadow: 0px 0px 10px 6px #cccc;
}

.shadow-bottom-1 {
  box-shadow: 0px 8px 10px 4px #cccc;
}

.shadow-bottom-2 {
  box-shadow: 0px 10px 10px 6px #cccc;
}

/*  Margins */

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.m-auto {
  margin: auto
}

/* Borders */
.border-0 {
  border: none;
}

.border-inset {
  border: inset;
  border-width: 1px;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}


/* Display */
.d-flex {
  display: flex;
}

.d-inline {
  display: inline;
}

.d-none {
  display: none;
}

.content-end {
  justify-content: end;
  text-align: end;
}

.text-right {
  text-align: right;
}

/* Responsive */
.row,
.row-lg,
.row-md,
.row-sm {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.grid-item {
  flex-grow: 1;
  /* height: fit-content; */
}

.f-25 {
  flex: 25% 1;
}

.f-50 {
  flex: 50% 1;
}

.f-75 {
  flex: 75% 1;
}

.f-100 {
  flex: 100% 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.centered {
  align-items: center;
  justify-content: center;
}

.align-baseline {
  align-items: baseline;
}

.gap-1 {
  grid-gap: 1rem;
  gap: 1rem;
}


/* Image gallery */
.image-gallery-image {
  border-radius: 25px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
  border: 4px solid var(--primary-color) !important;
}
 
.image-gallery-icon:hover, .image-gallery-icon:focus, .image-gallery-icon.active {
  color: var(--primary-color) !important;
} 

.image-gallery-bullet:hover, .image-gallery-bullet:focus {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

@media screen and (max-width: 1200px) {
  .row-lg {
    flex-direction: column;
  }

  .d-none-lg {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .row-md {
    flex-direction: column;
  }

  .d-none-md {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .row-sm {
    flex-direction: column;
  }

  .d-none-sm {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .row-xs {
    flex-direction: column;
  }

  .d-none-xs {
    display: none;
  }

  .container {
    padding: 10px;
  }

  .photo {
    border-image-width: auto;
    align-content: center;
  }
}
